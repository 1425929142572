import mqtt from 'mqtt'
import RFS from './RFS'
import app from '../main';

class MQTT {

    constructor(options, callback) {
        this.options = options;
        // this.clientId = options.clientId;
        // this.client = "";
        this.msgIdSequence = 1;
        this.initMqtt(callback)
        // if (this.options && this.options.clientId && this.options.userName && this.options.password) {
        //     // this.deviceSecret = this.options.deviceSecret
        //     this.initMqtt(callback)
        // } else {
        //     console.error('设备信息不全,初始化失败,等待信息初始化')
        // }
    }


    getMsgId() {
        return this.msgIdSequence++
    }

    initMqtt(callback) {
        let vm = this
        let commonApi = `wss://${window.location.host}${process.env.NODE_ENV === 'production' ? ':8183' : ''}/mqtt`;
        // let commonApi = 'wss://test.funlink.cloud:8183/mqtt';
        // let commonApi = 'ws://broker.emqx.io:8083/mqtt';

        vm.client = mqtt.connect(commonApi, this.options)
        console.log("initMqtt", this.options)
        this.client.on('connect', function () {
            console.log('连接成功....')
            callback(true)
        })
        // 如果连接错误，打印错误
        vm.client.on('error', function (err) {
            console.log('连接失败....')
            console.log('err=>', err)
            vm.client.end()

            callback(false, err);
        })
    }


    /**
     * 消息订阅
     */
    mqttReceive(callback, topicAll) {
        let vm = this
        topicAll.forEach(topic => {
            vm.client.subscribe(topic, function (err) {
                if (!err) {
                    console.log('订阅' + topic + ' success!')
                } else {
                    // 打印错误
                    console.log('订阅 err: ' + topic + ' ', err)
                }
            })
        })
        vm.client.on('message', (topic, message) => {
            let msg = JSON.parse(app.$config.Uint8ArrayToString(message));
            console.log("原始数据 =》", "topic:" + topic + "=>", msg);

            callback(topic, msg)
        })
    }

    /**
     * 上报
     * @param topic
     * @param msgId
     * @param data
     */
    messagePush(topic, data) {
        this.client.publish(topic, data, {qos: 1})
        console.log("设备上报 =》", topic, data)
    }


    /**
     * 批量主动上报
     * @param json
     */
    messageSends(json) {
        let vm = this
        let data = json

        let topicStr = 'batch_state/' + this.clientId + '/' + this.deviceSecret
        let msgId = this.getMsgId()

        console.log('主动批量上报[' + topicStr + ']: ', data)

        this.messagePush(topicStr, msgId, JSON.parse(data))
    }

    /**
     * 单属性上报（模拟手机->真实设备）
     * @param json
     * @param topic
     * @param userId
     */
    messageSend(json, topic, userId) {
        let vm = this
        let data = json

        let topicStr = topic + '/' + this.clientId + '/M-A-' + userId;
        let msgId = this.getMsgId()
        console.log('单属性上报[' + topicStr + ']: ', data)

        this.messagePush(topicStr, msgId, JSON.parse(data))
    }

    /**
     * 关闭mqtt
     */
    closeMQTT() {
        if (this.client.end) {
            this.client.end()
        }
    }
}

export default MQTT;
